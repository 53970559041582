<template>
  <div>
    <div class="field">
      <label
        v-if="label"
        class="label"
      >
        {{ label }}
      </label>
      <div :class="streetAddressIconsClass">
        <input
          :class="streetAddressClass"
          type="text"
          placeholder="Street Address"
          v-model.trim="$v.address.street_address.$model"
          @input="update"
        />
        <span
          v-if="$v.address.street_address.$invalid"
          class="icon is-small is-right"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </div>
    </div>
    <div class="field is-grouped">
      <p :class="localityIconsClass">
        <input
          :class="localityClass"
          type="text"
          placeholder="City"
          v-model.trim="$v.address.locality.$model"
          @input="update"
        />
        <span
          v-if="$v.address.locality.$invalid"
          class="icon is-small is-right"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </p>
      <p :class="administrativeAreaLevel1IconsClass">
        <input
          :class="administrativeAreaLevel1Class"
          type="text"
          placeholder="State"
          v-model.trim="$v.address.administrative_area_level_1.$model"
          @input="update"
        />
        <span
          v-if="$v.address.administrative_area_level_1.$invalid"
          class="icon is-small is-right"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </p>
      <p :class="postalCodeIconsClass">
        <input
          :class="postalCodeClass"
          type="text"
          placeholder="Postal Code"
          v-model.trim="$v.address.postal_code.$model"
          @input="update"
        />
        <span
          v-if="$v.address.postal_code.$invalid"
          class="icon is-small is-right"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <div :class="countryIconsClass">
        <input
          :class="countryClass"
          type="text"
          placeholder="Country"
          v-model.trim="$v.address.country.$model"
          @input="update"
        />
        <span
          v-if="$v.address.country.$invalid"
          class="icon is-small is-right"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, requiredIf } from "vuelidate/lib/validators"

export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      address: {
        street_address: null,
        locality: null,
        administrative_area_level_1: null,
        postal_code: null,
        country: null
      }
    }
  },
  validations: {
    address: {
      street_address: { required: requiredIf("required") },
      locality: { required: requiredIf("required") },
      administrative_area_level_1: {
        required: requiredIf("required"),
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      postal_code: {
        required: requiredIf("required"),
        minLength: minLength(5),
        maxLength: maxLength(10)
      },
      country: {
        required: requiredIf("required"),
        minLength: minLength(2),
        maxLength: maxLength(2)
      }
    }
  },
  computed: {
    streetAddressClass: function() {
      return (this.$v.address.street_address.$invalid) ? "input is-danger" : "input"
    },
    localityClass: function() {
      return (this.$v.address.locality.$invalid) ? "input is-danger" : "input"
    },
    administrativeAreaLevel1Class: function() {
      return (this.$v.address.administrative_area_level_1.$invalid) ? "input is-danger" : "input"
    },
    postalCodeClass: function() {
      return (this.$v.address.postal_code.$invalid) ? "input is-danger" : "input"
    },
    countryClass: function() {
      return (this.$v.address.country.$invalid) ? "input is-danger" : "input"
    },
    streetAddressIconsClass: function() {
      return (this.$v.address.street_address.$invalid) ? "control has-icons-right" : "control"
    },
    localityIconsClass: function() {
      return (this.$v.address.locality.$invalid) ? "control is-expanded has-icons-right" : "control is-expanded"
    },
    administrativeAreaLevel1IconsClass: function() {
      return (this.$v.address.administrative_area_level_1.$invalid) ? "control has-icons-right" : "control"
    },
    postalCodeIconsClass: function() {
      return (this.$v.address.postal_code.$invalid) ? "control has-icons-right" : "control"
    },
    countryIconsClass: function() {
      return (this.$v.address.country.$invalid) ? "control has-icons-right" : "control"
    }
  },
  methods: {
    update() {
      this.$emit('input', this.address)
    }
  },
  mounted() {
    this.address = this.value
  }
}
</script>
