<template>
  <AdminPage>

     <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <!--
          <div 
            class="field has-addons"
          >
            <p class="control">
              <span class="select">
                <select>
                  <option>All businesses</option>
                  <option>Top 10% lifetime revenue</option>
                  <option>Active - has balance</option>
                   <option>Active - no balance</option>
                  <option>Cancelled</option>
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All locations</option>
                  <option>Burlington, VT</option>
                  <option>..</option>
                </select>
              </span>
            </p>
          </div>
          -->
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            @click="showCharityCreate"
          >
            New Charity
          </button>
        </div>
        <p class="level-item"><strong>{{ charitiesCount }}</strong></p>
      </div>
    </nav>

    <form
      v-if="showCharityCreateEdit"
      @submit.prevent="charitySave"
    >
      <BaseModal
        :title="charityEditModalTitle"
        @close="hideCharityCreateEdit"
      >
        <BaseInput
          label="Name"
          v-model.trim="$v.charity.name.$model"
          :validation="$v.charity.name"
        />
        <BaseInput
          label="Website"
          v-model.trim="$v.charity.website.$model"
          :validation="$v.charity.website"
        />
        <BaseInput
          label="Phone"
          v-model="$v.charity.phone.$model"
          :validation="$v.charity.phone"
          :mask="'(###) ###-####'"
        />
        <BaseTextarea
          label="About"
          v-model="$v.charity.about.$model"
          :validation="$v.charity.about"
        />
        <BaseCheckbox
          label="Enabled"
          v-model="$v.charity.enabled.$model"
        />

        <hr />

        <BaseAddressInput
          label="Address"
          v-model="charity.address"
          :required="true"
        />
        <BaseInput
          label="Latitude"
          v-model.trim="$v.charity.lat.$model"
          :validation="$v.charity.lat"
        />
        <BaseInput
          label="Longitude"
          v-model.trim="$v.charity.lng.$model"
          :validation="$v.charity.lng"
        />

        <hr />

        <BaseInput
          label="Payout Contact Name"
          v-model.trim="$v.charity.payable_contact_name.$model"
          :validation="$v.charity.payable_contact_name"
        />
        <BaseInput
          label="Payout Contact Email"
          type="email"
          v-model.trim="$v.charity.payable_contact_email.$model"
          :validation="$v.charity.payable_contact_email"
        />
        <BaseInput
          label="Payout Contact Phone"
          v-model="$v.charity.payable_contact_phone.$model"
          :validation="$v.charity.payable_contact_phone"
          :mask="'(###) ###-####'"
        />

        <hr />

        <BaseInput
          label="Make Payable To"
          v-model.trim="$v.charity.payable_to.$model"
          :validation="$v.charity.payable_to"
        />
        <BaseAddressInput
          label="Payout Address"
          v-model="charity.payable_address"
        />
        <template v-slot:footer>
          <button
            class="button"
            @click="hideCharityCreateEdit"
          >
            Cancel
          </button>
          <BaseSubmit
            :label="charityEditModalButtonLabel"
            :disabled="$v.$invalid"
            :submitting="submittingCharitySave"
            :clearShow="false"
          />
        </template>
      </BaseModal>
    </form>

    <b-table
      :data="charities"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="name"
      default-sort-direction="desc"
    >
      <b-table-column
        field="name"
        label="Charity"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="enabled"
        label="Status"
        sortable
        v-slot="props"
      >
        {{ props.row.enabled ? "Enabled" : "Disabled" }}
      </b-table-column>

      <b-table-column
        field="slug"
        label="Slug"
        sortable
        v-slot="props"
      >
        {{ props.row.slug }}
      </b-table-column>

      <b-table-column
        field="locality"
        label="City"
        sortable
        v-slot="props"
      >
        {{ props.row.locality }}
      </b-table-column>

      <b-table-column
        field="administrative_area_level_1"
        label="State"
        sortable
        v-slot="props"
      >
        {{ props.row.administrative_area_level_1 }}
      </b-table-column>

      <b-table-column
        field="country"
        label="Country"
        sortable
        v-slot="props"
      >
        {{ props.row.country }}
      </b-table-column>

      <b-table-column
        label=""
        v-slot="props"
      >
        <button
          class="button"
          @click="showCharityEdit(props.row)"
        >
          <span class="icon is-small">
            <i class="fas fa-edit"></i>
          </span>
        </button>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No charities</div>
     </template>
    </b-table>
  </AdminPage>
</template>

<script>
import { AuthError, NetworkError } from "@/errors"
import { email, maxLength, minLength, required, url } from "vuelidate/lib/validators"
import AdminPage from "@/components/AdminPage.vue"
import BaseInput from "@/components/form/BaseInput.vue"
import BaseTextarea from "@/components/form/BaseTextarea.vue"
import BaseCheckbox from "@/components/form/BaseCheckbox.vue"
import BaseAddressInput from "@/components/form/BaseAddressInput.vue"
import BaseSubmit from "@/components/form/BaseSubmit.vue"
import BaseModal from "@/components/modal/BaseModal.vue"
//import ConfirmButton from "@/components/ConfirmButton.vue"
import api from "@/service/api.js"

export default {
  components: {
    AdminPage,
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    BaseAddressInput,
    BaseSubmit,
    BaseModal
    //ConfirmButton
  },
  validations: {
    charity: {
      name: { required },
      image: { },
      website: { url },
      phone: { minLength: minLength(14) },
      about: {
        required,
        maxLength: maxLength(255)
      },
      enabled: { },
      address: {
        street_address: { },
        locality: { required },
        administrative_area_level_1: { required },
        postal_code: { },
        country: { },
      },
      lat: {  },
      lng: {  },
      payable_contact_name: {  },
      payable_contact_email: { email },
      payable_contact_phone: { minLength: minLength(14) },
      payable_to: { },
      payable_address: {
        street_address: { },
        locality: { },
        administrative_area_level_1: { },
        postal_code: {  },
        country: { },
      }
    }
  },
  computed: {
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    charitiesCount: function() {
      return this.charities.length
    },
    charityEditModalTitle: function() {
      switch (this.charityEditMode) {
        case "edit":
          return "Edit Charity"
        case "create":
          return "Create Charity"
      }
      return ""
    },
    charityEditModalButtonLabel: function() {
      switch (this.charityEditMode) {
        case "edit":
          return "Update"
        case "create":
          return "Create"
      }
      return ""
    }
  },
  data() {
    return {
      loading: false,
      charities: [],
      charity: {
        name: "",
        image: "",
        website: "",
        phone: "",
        about: "",
        enabled: true,
        lat: "",
        lng: "",
        payable_contact_name: "",
        payable_contact_email: "",
        payable_contact_phone: "",
        payable_to: "",
        address: {
          street_address: "",
          locality: "",
          administrative_area_level_1: "",
          postal_code: "",
          country: "",
        },
        payable_address: {
          street_address: "",
          locality: "",
          administrative_area_level_1: "",
          postal_code: "",
          country: "",
        }
      },
      showCharityCreateEdit: false,
      submittingCharitySave: false,
      charityEditMode: ""
    }
  },
  mounted() {
    this.updateCharities()
  },
  methods: {
    updateCharities() {
      this.loading = true

      this.$store.dispatch("getCharities")
        .then(resp => {
          this.charities = resp
        })
        .catch(error => {
          this.error = error.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    showCharityCreate() {
      this.charity = {
        slug: null,
        name: "",
        image: "",
        website: "",
        phone: "",
        about: "",
        enabled: true,
        lat: "",
        lng: "",
        payable_contact_name: "",
        payable_contact_email: "",
        payable_contact_phone: "",
        payable_to: "",
        address: {
          street_address: "",
          locality: "",
          administrative_area_level_1: "",
          postal_code: "",
          country: "",
        },
        payable_address: {
          street_address: "",
          locality: "",
          administrative_area_level_1: "",
          postal_code: "",
          country: "",
        }
      }
      this.charityEditMode = "create"
      this.showCharityCreateEdit = true
    },
    showCharityEdit(charity) {
      api.getAdminCharity(charity.slug)
        .then(resp => {
          const adminCharity = resp.data
          this.charity = {
            slug: adminCharity.slug,
            name: adminCharity.name,
            image: adminCharity.image,
            website: adminCharity.website,
            phone: adminCharity.phone,
            about: adminCharity.about,
            enabled: adminCharity.enabled,
            lat: adminCharity.lat,
            lng: adminCharity.lng,
            payable_contact_name: adminCharity.payable_contact_name,
            payable_contact_email: adminCharity.payable_contact_email,
            payable_contact_phone: adminCharity.payable_contact_phone,
            payable_to: adminCharity.payable_to,
            address: {
              street_address: adminCharity.street_address,
              locality: adminCharity.locality,
              administrative_area_level_1: adminCharity.administrative_area_level_1,
              postal_code: adminCharity.postal_code,
              country: adminCharity.country,
            },
            payable_address: {
              street_address: adminCharity.payable_street_address,
              locality: adminCharity.payable_locality,
              administrative_area_level_1: adminCharity.payable_administrative_area_level_1,
              postal_code: adminCharity.payable_postal_code,
              country: adminCharity.payable_country
            }
          }
          this.charityEditMode = "edit"
          this.showCharityCreateEdit = true
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    hideCharityCreateEdit() {
      this.showCharityCreateEdit = false
    },
    charitySave() {
      this.submittingCharitySave = true

      const charity = {
        name: this.charity.name,
        image: this.charity.image,
        website: this.charity.website,
        phone: this.charity.phone,
        about: this.charity.about,
        enabled: this.charity.enabled,
        street_address: this.charity.address.street_address,
        locality: this.charity.address.locality,
        administrative_area_level_1: this.charity.address.administrative_area_level_1,
        postal_code: this.charity.address.postal_code,
        country: this.charity.address.country,
        lat: this.charity.lat,
        lng: this.charity.lng,
        payable_contact_name: this.charity.payable_contact_name,
        payable_contact_email: this.charity.payable_contact_email,
        payable_contact_phone: this.charity.payable_contact_phone,
        payable_to: this.charity.payable_to,
        payable_street_address: this.charity.payable_address.street_address,
        payable_locality: this.charity.payable_address.locality,
        payable_administrative_area_level_1: this.charity.payable_address.administrative_area_level_1,
        payable_postal_code: this.charity.payable_address.postal_code,
        payable_country: this.charity.payable_address.country,
      }
      if (this.charity.slug) {
        charity.slug = this.charity.slug
      }

      let params
      let promise
      switch (this.charityEditMode) {
        case "create":
          params = { charity: charity }
          promise = this.$store.dispatch("createCharity", params)
          break
        case "edit":
          params = { slug: charity.slug, charity: charity }
          promise = this.$store.dispatch("updateCharity", params)
          break
      }
      promise
        .then(resp => {
          this.success = `Successfully created '${resp.name}' charity!`
          this.updateCharities()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submittingCharitySave = false
          this.hideCharityCreateEdit()
        })
    }
  }
}
</script>
